import React from "react";

export const introImg = (
  <svg
    version="1.1"
    id="introImg"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 319.5 309.3"
    //  width="460px"
    //  height="60px"
    //  style="enable-background:new 0 0 319.5 309.3;"
    //  xml:space="preserve"
  >
    <g>
      <g>
        <path
          className="st0"
          d="M230.5,100.7c-0.4,0-0.7-0.3-0.7-0.7V71.5c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7V100
            C231.2,100.4,230.9,100.7,230.5,100.7z"
        />
        <g>
          <g>
            <rect x="228" y="69.8" className="st0" width="4.9" height="10.7" />
            <path
              className="st0"
              d="M232.3,81.4c-1,0.6-2.5,0.6-3.5,0c-1-0.6-1-1.5,0-2c1-0.6,2.5-0.6,3.5,0C233.2,80,233.2,80.9,232.3,81.4z"
            />
          </g>
          <g>
            <rect x="226.5" y="54.9" className="st0" width="8" height="16.7" />
            <path
              className="st1"
              d="M228,71.5v3.6c1.5,0.7,3.5,0.7,4.9,0v-3.6C231.5,70.8,229.5,70.8,228,71.5z"
            />
            <path
              className="st0"
              d="M233.3,73.3c-1.6,0.9-4.1,0.9-5.7,0c-1.6-0.9-1.6-2.4,0-3.3c1.6-0.9,4.1-0.9,5.7,0
                  C234.9,70.9,234.9,72.4,233.3,73.3z"
            />
          </g>
        </g>
      </g>
      <g>
        <path
          className="st0"
          d="M258.3,116.3c-0.4,0-0.7-0.3-0.7-0.7V87.2c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7v28.5
            C259,116,258.6,116.3,258.3,116.3z"
        />
        <g>
          <g>
            <rect x="255.8" y="85.4" className="st0" width="4.9" height="10.7" />
            <path
              className="st0"
              d="M260,97c-1,0.6-2.5,0.6-3.5,0c-1-0.6-1-1.5,0-2c1-0.6,2.5-0.6,3.5,0C261,95.6,261,96.5,260,97z"
            />
          </g>
          <g>
            <rect x="254.3" y="70.5" className="st0" width="8" height="16.7" />
            <path
              className="st1"
              d="M255.8,87.1v3.6c1.5,0.7,3.5,0.7,4.9,0v-3.6C259.3,86.5,257.2,86.5,255.8,87.1z"
            />
            <path
              className="st0"
              d="M261.1,88.9c-1.6,0.9-4.1,0.9-5.7,0c-1.6-0.9-1.6-2.4,0-3.3c1.6-0.9,4.1-0.9,5.7,0
                  C262.7,86.5,262.7,88,261.1,88.9z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <polygon className="st0" points="285.2,98.3 296,91.9 285.7,79.2 283.1,83.6 			" />
          <path
            className="st0"
            d="M207.2,28.2l17.1-9.9c0,0-0.6,9.8-0.6,9.9s-9.8,3.1-9.8,3.1L207.2,28.2z"
          />
          <path
            className="st0"
            d="M288.2,66.2c-1.3-0.8-2.6-1.2-3.7-1.4c-1.7-9.1-8-18.7-15.6-23.1c-2.3-1.3-4.4-2-6.4-2.2
               c-1-11-8.7-23.5-18-28.9c-4.9-2.8-9.3-3.1-12.5-1.4l0,0l-0.2,0.1c-0.1,0-0.2,0.1-0.2,0.1l-10.1,6l3.4,2.7c-3,1.3-4.9,4.7-4.9,9.7
               c0,9.2,6.5,20.4,14.5,25l53.8,38.4c6,3.5,10.9,0.7,10.9-6.3S294.2,69.6,288.2,66.2z"
          />
          <path
            className="st0"
            d="M220.5,23.7l23.2-13.6c-4.5-2.3-8.6-2.5-11.6-0.9l0,0l-0.2,0.1c-0.1,0-0.2,0.1-0.2,0.1l-10.1,6l3.4,2.7
               C222.8,19,221.2,20.9,220.5,23.7z"
          />
          <path
            className="st0"
            d="M263.3,63.6l16.9-9.9c-2.9-5-6.9-9.4-11.3-12c-0.6-0.3-1.2-0.6-1.7-0.9l-21.5,12.6L263.3,63.6z"
          />
          <path
            className="st2"
            d="M226.3,16.5l7.5,4.4l13.8-8.1c-1-0.8-2-1.5-3.1-2.1c-1.7-1-3.3-1.6-4.8-2L226.3,16.5z"
          />
          <path
            className="st2"
            d="M278.4,50.7l-13.5,8l4.7,5.2l12.3-7.2C280.9,54.6,279.7,52.6,278.4,50.7z"
          />
          <path
            className="st2"
            d="M277.6,72.4c-1.3-0.8-2.6-1.2-3.7-1.4c-1.7-9.1-8.1-18.7-15.6-23.1c-2.3-1.3-4.4-2-6.4-2.2
               c-1-11-8.7-23.5-18-28.9c-10-5.8-18.1-1.1-18.1,10.5c0,0.4,0,0.8,0,1.2c-7.3-3.4-13,0.4-13,9.1c0,9.2,6.5,20.4,14.5,25l60.3,34.8
               c6,3.5,10.9,0.7,10.9-6.3C288.5,84.3,283.6,75.9,277.6,72.4z"
          />
          <g>
            <path
              className="st3"
              d="M215.9,28.5c0-0.4,0-0.8,0-1.2c0,0,0,0,0,0c-4-1.6-7.5-0.9-9.7,1.6C208.5,27,212,26.7,215.9,28.5z"
            />
            <path
              className="st3"
              d="M278.5,71.5c-1.3-0.8-2.6-1.2-3.7-1.4c-1.7-9.1-8-18.7-15.6-23.1c-2.3-1.3-4.4-2-6.4-2.2
                  c-1-11-8.7-23.5-18-28.9c-6.2-3.6-11.7-3.1-15,0.5c3.3-2.8,8.4-2.9,14.1,0.3c9.3,5.4,17,17.9,18,28.9c2,0.2,4.1,0.9,6.4,2.2
                  c7.5,4.4,13.9,14,15.6,23.1c1.2,0.2,2.4,0.6,3.7,1.4c6,3.5,10.9,11.9,10.9,18.8c0,2.5-0.6,4.5-1.7,5.8c1.6-1.3,2.6-3.5,2.6-6.6
                  C289.3,83.4,284.5,75,278.5,71.5z"
            />
          </g>
          <g>
            <path
              className="st1"
              d="M214.6,31.3l4.5,2.1l-0.2-5c0-0.4,0-0.7,0-1c0-4.5,1.3-7.7,3.8-9.1c2.4-1.4,5.9-1,9.8,1.3
                  c8.3,4.8,15.5,16.5,16.5,26.6l0.2,2.5l2.5,0.2c1.6,0.1,3.3,0.7,5.1,1.8c6.6,3.8,12.5,12.6,14.1,21l0.4,2.1l2.1,0.3
                  c0.8,0.1,1.7,0.5,2.7,1c5.1,2.9,9.3,10.3,9.3,16.2c0,2.2-0.6,3.8-1.7,4.4c-1.1,0.6-2.8,0.3-4.6-0.8L218.8,60
                  c-7-4-12.9-14.3-12.9-22.4c0-3.2,0.9-5.6,2.6-6.6c0.7-0.4,1.5-0.6,2.4-0.6C212,30.3,213.2,30.7,214.6,31.3z"
            />
          </g>
        </g>
        <g>
          <polygon className="st0" points="244.7,69.1 246.3,68.3 244.7,67.1 			" />
          <polygon className="st0" points="239.9,47.6 241.5,46.8 241.5,47.6 			" />
          <polygon className="st0" points="249.2,64.1 250.8,63.2 247.7,60.3 			" />
          <g>
            <path
              className="st0"
              d="M245.8,68.2l-7.5-4.4c-0.4-0.2-0.7-0.8-0.7-1.3l0-8.7l8.9,5.3l0,8.7C246.5,68.3,246.2,68.5,245.8,68.2z"
            />
            <path
              className="st0"
              d="M241.4,46.8l-8.1,5.2c-0.3,0.2-0.1,1.1,0.5,1.4l8.3,4.9l8.3,4.9c0.5,0.3,0.8-0.2,0.5-0.8l-8.1-14.8
                  C242.4,47,241.8,46.6,241.4,46.8z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M244.2,69l-7.5-4.4c-0.4-0.2-0.7-0.8-0.7-1.3l0-8.7l8.9,5.3l0,8.7C244.9,69.1,244.6,69.3,244.2,69z"
            />
            <path
              className="st2"
              d="M239.8,47.6l-8.1,5.2c-0.3,0.2-0.1,1.1,0.5,1.4l8.3,4.9l8.3,4.9c0.5,0.3,0.8-0.2,0.5-0.8l-8.1-14.8
                  C240.8,47.8,240.2,47.4,239.8,47.6z"
            />
          </g>
        </g>
      </g>
      <path
        className="st2"
        d="M229.7,78.6c0,0.5-0.3,0.7-0.8,0.4c-0.4-0.2-0.8-0.8-0.8-1.3c0-0.5,0.3-0.7,0.8-0.4
         C229.4,77.5,229.7,78.1,229.7,78.6z"
      />
      <path
        className="st2"
        d="M257.6,94.4c0,0.5-0.3,0.7-0.8,0.4c-0.4-0.2-0.8-0.8-0.8-1.3c0-0.5,0.3-0.7,0.8-0.4
         C257.2,93.3,257.6,93.9,257.6,94.4z"
      />
    </g>
    <g>
      <path
        className="st4"
        d="M40.2,215.5l-17.3-10c-7.2-4.2-7.2-11,0-15.1l15.8-9.1c3.2-1.9,3.2-4.9,0-6.8l-13.8-7.9
         c-7.2-4.2-7.2-11,0-15.1l17.2-10c7.2-4.2,19-4.2,26.2,0l11.1,6.4l-7.2,4.2L61,145.6c-3.2-1.9-8.5-1.9-11.7,0l-17.2,10
         c-3.2,1.9-3.2,4.9,0,6.8l13.8,7.9c7.2,4.2,7.2,11,0,15.1l-15.8,9.1c-3.2,1.9-3.2,4.9,0,6.8l17.3,10L40.2,215.5z"
      />
    </g>
    <g>
      <g>
        <path
          className="st0"
          d="M17.6,190l-0.2-0.4v1.7l0,0c-0.1,2.8,1.7,5.6,5.4,7.7l17.3,10l7.2-4.2l-17.3-10c-1-0.6-1.7-1.3-2.1-2
            c-3.9-0.3-6.9-2.3-9.6-4.8C18,188.7,17.8,189.3,17.6,190z"
        />
        <path
          className="st5"
          d="M68.3,135c-7.2-4.2-19-4.2-26.2,0l-17.2,10c-2.1,1.2-3.6,2.7-4.5,4.3c2.8,2.6,5.5,5.5,9.9,5.1
            c-1.2-1.7-0.6-3.7,1.8-5.1l17.2-10c3.2-1.9,8.5-1.9,11.7,0l11.1,6.4l7.2-4.2L68.3,135z"
        />
        <path
          className="st0"
          d="M48.7,165.9c-0.8-0.8-1.7-1.5-2.8-2.1l-13.8-7.9c-0.8-0.5-1.4-1-1.8-1.6c-4.4,0.4-7.1-2.4-9.9-5.1
            c-0.3,0.6-0.5,1.1-0.7,1.7l-0.2-0.3v1.9l0,0c0,2.8,1.8,5.5,5.4,7.6l13.8,7.9c2.1,1.2,2.8,2.9,2.2,4.5
            C43.6,171.8,45.9,168.3,48.7,165.9z"
        />
        <path
          className="st5"
          d="M51,169.8c-0.3-1.4-1.1-2.7-2.4-3.9c-2.8,2.3-5,5.9-7.9,6.6c-0.3,0.8-1.1,1.6-2.2,2.3l-15.8,9.1
            c-2.1,1.2-3.6,2.7-4.5,4.2c2.8,2.5,5.8,4.5,9.6,4.8c-0.9-1.6-0.2-3.5,2.1-4.8l15.8-9.1c3.6-2.1,5.4-4.8,5.4-7.5l0,0v-0.1v0v-1.8
            L51,169.8z"
        />
        <g>
          <path
            className="st3"
            d="M24.8,158.2l13.8,7.9c1.4,0.8,2.2,1.9,2.4,3c0.2-1.3-0.6-2.7-2.4-3.8l-13.8-7.9c-3.4-2-5.2-4.6-5.4-7.2
               C19.3,153.1,21.1,156,24.8,158.2z"
          />
          <path
            className="st3"
            d="M40.2,206.3l-17.3-10c-3.4-2-5.2-4.6-5.4-7.2c-0.2,2.9,1.6,5.8,5.4,7.9l17.3,10l7.2-4.2l-0.7-0.4L40.2,206.3
               z"
          />
          <path
            className="st3"
            d="M72.1,142.9L61,136.5c-3.2-1.9-8.5-1.9-11.7,0l-17.2,10c-1.8,1-2.6,2.4-2.4,3.8c0.2-1.1,0.9-2.2,2.4-3
               l17.2-10c3.2-1.9,8.5-1.9,11.7,0l11.1,6.4l7.2-4.2l-0.7-0.4L72.1,142.9z"
          />
          <path
            className="st3"
            d="M45.8,176.3l-15.8,9.1c-1.8,1-2.6,2.4-2.4,3.8c0.2-1.1,0.9-2.2,2.4-3l15.8-9.1c3.8-2.2,5.6-5.1,5.4-7.9
               C51.1,171.7,49.3,174.3,45.8,176.3z"
          />
        </g>
        <g>
          <g>
            <path
              className="st2"
              d="M40.2,207.1l-17.3-10c-7.2-4.2-7.2-11,0-15.1l15.8-9.1c3.2-1.9,3.2-4.9,0-6.8l-13.8-7.9
                  c-7.2-4.2-7.2-11,0-15.1l17.2-10c7.2-4.2,19-4.2,26.2,0l11.1,6.4l-7.2,4.2L61,137.3c-3.2-1.9-8.5-1.9-11.7,0l-17.2,10
                  c-3.2,1.9-3.2,4.9,0,6.8l13.8,7.9c7.2,4.2,7.2,11,0,15.1l-15.8,9.1c-3.2,1.9-3.2,4.9,0,6.8l17.3,10L40.2,207.1z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M48.9,201.5L48.9,201.5c-0.3-0.2-0.8-0.2-1.2,0.1l-8,4.6c-0.9,0.5-1.7,1.8-1.7,2.9c0,0.5,0.2,0.8,0.4,1
                  l8.6,5l10.3-8.7L48.9,201.5z"
            />
            <path
              className="st0"
              d="M56.1,206.5l-8,4.6c-0.9,0.5-1.7,1.8-1.7,2.9s0.7,1.5,1.7,1l8-4.6c0.9-0.5,1.7-1.8,1.7-2.9
                  S57,206,56.1,206.5z"
            />
          </g>
        </g>
      </g>
      <path
        className="st0"
        d="M71.1,131.9L71.1,131.9c-0.3-0.2-0.8-0.2-1.2,0.1l-8,4.6c-0.9,0.5-1.7,1.8-1.7,2.9c0,0.5,0.2,0.8,0.4,1l8.6,5
         l10.3-8.7L71.1,131.9z"
      />
      <g>
        <g>
          <g>
            <path
              className="st4"
              d="M109.5,194.9l-41.5-23.6c-1.5-0.9-1.3-2.4,0.4-3.4l23.9-13.8c1.8-1,4.4-1.1,5.9-0.2l41.5,23.6
                  c1.5,0.9,1.3,2.4-0.4,3.4l-23.9,13.8C113.7,195.7,111.1,195.8,109.5,194.9z"
            />
            <path
              className="st1"
              d="M143.4,169.6L143.4,169.6V72.8L105,62.7l-7.1-4.1c-1.6-0.9-4.5-0.8-6.3,0.3l-6.6,3.8l-20.5,0l6.1,93.4
                  c0,0.6,0.3,1.2,1.1,1.7l38.4,29c1.6,0.9,4.5,0.8,6.3-0.3l25.6-14.8C143,171.1,143.5,170.3,143.4,169.6z"
            />
            <path
              className="st2"
              d="M94.9,58c-1.2,0-2.4,0.3-3.4,0.9l-6.6,3.8l-20.5,0v97c0,0.6,0.3,1.2,1.1,1.7l44.5,25.4
                  c0.8,0.5,1.8,0.7,2.9,0.6l0.5-97.6L94.9,58z"
            />
            <path className="st0" d="M110.4,187c1.4,0.6,3.4,0.6,5.1-0.1V88.6h-5.1V187z" />
            <path
              className="st2"
              d="M109.9,89.8L65.5,64.4c-1.6-0.9-1.4-2.6,0.5-3.7L91.5,46c1.9-1.1,4.7-1.2,6.3-0.3l44.5,25.4
                  c1.6,0.9,1.4,2.6-0.5,3.7l-25.6,14.8C114.4,90.6,111.6,90.7,109.9,89.8z"
            />
            <path
              className="st6"
              d="M141.9,72.9l-25.6,14.8c-1.9,1.1-4.7,1.2-6.3,0.3L65.5,62.6c-0.4-0.2-0.6-0.5-0.8-0.7
                  c-0.6,0.9-0.3,1.9,0.8,2.5l44.5,25.4c1.6,0.9,4.5,0.8,6.3-0.3l25.6-14.8c1.5-0.8,1.9-2,1.3-2.9
                  C142.9,72.2,142.5,72.6,141.9,72.9z"
            />
            <g>
              <path
                className="st5"
                d="M111.2,79.5L81.6,62.3c-0.8-0.4-0.7-1.2,0.2-1.7l12-6.9c0.9-0.5,2.2-0.6,3-0.1l29.6,17.2
                     c0.8,0.4,0.7,1.2-0.2,1.7l-12,6.9C113.3,79.9,112,79.9,111.2,79.5z"
              />
              <g>
                <path
                  className="st2"
                  d="M95.2,63.1l-1.7-1c-0.1-0.1-0.1-0.2,0-0.2l1.7-1c0.1-0.1,0.3-0.1,0.4,0l1.7,1c0.1,0.1,0.1,0.2,0,0.2
                        l-1.7,1C95.5,63.2,95.3,63.2,95.2,63.1z"
                />
                <path
                  className="st2"
                  d="M101,66.4l-1.7-1c-0.1-0.1-0.1-0.2,0-0.2l1.7-1c0.1-0.1,0.3-0.1,0.4,0l1.7,1c0.1,0.1,0.1,0.2,0,0.2
                        l-1.7,1C101.2,66.5,101.1,66.5,101,66.4z"
                />
                <path
                  className="st2"
                  d="M106.7,69.7l-1.7-1c-0.1-0.1-0.1-0.2,0-0.2l1.7-1c0.1-0.1,0.3-0.1,0.4,0l1.7,1c0.1,0.1,0.1,0.2,0,0.2
                        l-1.7,1C107,69.8,106.8,69.8,106.7,69.7z"
                />
                <path
                  className="st2"
                  d="M112.4,73l-1.7-1c-0.1-0.1-0.1-0.2,0-0.2l1.7-1c0.1-0.1,0.3-0.1,0.4,0l1.7,1c0.1,0.1,0.1,0.2,0,0.2
                        l-1.7,1C112.7,73,112.5,73.1,112.4,73z"
                />
              </g>
              <path
                className="st0"
                d="M81.6,62.3L81.6,62.3c0.1,0,0.1-0.1,0.2-0.1l12-6.9c0.9-0.5,2.2-0.6,3-0.1l29.6,17.1
                     c0.7-0.5,0.7-1.2,0-1.6L96.8,53.6c-0.8-0.4-2.1-0.4-3,0.1l-12,6.9C80.9,61.1,80.8,61.9,81.6,62.3z"
              />
            </g>
          </g>
          <g>
            <path
              className="st5"
              d="M121.3,109l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6c0-1.3-0.8-1.8-1.7-1.3l-16.8,9.6c-0.9,0.5-1.7,2-1.7,3.3v6
                  C119.6,108.9,120.3,109.5,121.3,109z"
            />
            <path
              className="st3"
              d="M139,88.5c0.3,0.2,0.4,0.7,0.4,1.3v6c0,1.3-0.8,2.7-1.7,3.3l-16.8,9.6c-0.3,0.2-0.6,0.2-0.8,0.2
                  c0.3,0.3,0.8,0.3,1.3,0l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6C139.8,89.2,139.5,88.6,139,88.5z"
            />
            <g>
              <path
                className="st1"
                d="M126.4,102.4l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C126,102.4,126.1,102.5,126.4,102.4z"
              />
              <path
                className="st3"
                d="M131.5,99.4l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C131.1,99.4,131.3,99.5,131.5,99.4z"
              />
            </g>
          </g>
          <g>
            <path
              className="st5"
              d="M121.3,152.5l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6c0-1.3-0.8-1.8-1.7-1.3l-16.8,9.6c-0.9,0.5-1.7,2-1.7,3.3v6
                  C119.6,152.4,120.3,153,121.3,152.5z"
            />
            <path
              className="st3"
              d="M139,132c0.3,0.2,0.4,0.7,0.4,1.3v6c0,1.3-0.8,2.7-1.7,3.3l-16.8,9.6c-0.3,0.2-0.6,0.2-0.8,0.2
                  c0.3,0.3,0.8,0.3,1.3,0l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6C139.8,132.7,139.5,132.1,139,132z"
            />
            <g>
              <path
                className="st1"
                d="M126.4,145.9l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C126,145.9,126.1,146,126.4,145.9z"
              />
              <path
                className="st3"
                d="M131.5,142.9l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C131.1,142.9,131.3,143.1,131.5,142.9z"
              />
            </g>
          </g>
          <g>
            <path
              className="st5"
              d="M121.3,174.2l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6c0-1.3-0.8-1.8-1.7-1.3l-16.8,9.6c-0.9,0.5-1.7,2-1.7,3.3v6
                  C119.6,174.2,120.3,174.8,121.3,174.2z"
            />
            <path
              className="st3"
              d="M139,153.8c0.3,0.2,0.4,0.7,0.4,1.3v6c0,1.3-0.8,2.7-1.7,3.3l-16.8,9.6c-0.3,0.2-0.6,0.2-0.8,0.2
                  c0.3,0.3,0.8,0.3,1.3,0l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6C139.8,154.4,139.5,153.9,139,153.8z"
            />
            <g>
              <path
                className="st1"
                d="M126.4,167.7l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C126,167.7,126.1,167.8,126.4,167.7z"
              />
              <path
                className="st3"
                d="M131.5,164.7l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C131.1,164.7,131.3,164.8,131.5,164.7z"
              />
            </g>
          </g>
          <g>
            <path
              className="st5"
              d="M121.3,130.7l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6c0-1.3-0.8-1.8-1.7-1.3l-16.8,9.6c-0.9,0.5-1.7,2-1.7,3.3v6
                  C119.6,130.7,120.3,131.2,121.3,130.7z"
            />
            <path
              className="st3"
              d="M139,110.3c0.3,0.2,0.4,0.7,0.4,1.3v6c0,1.3-0.8,2.7-1.7,3.3l-16.8,9.6c-0.3,0.2-0.6,0.2-0.8,0.2
                  c0.3,0.3,0.8,0.3,1.3,0l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6C139.8,110.9,139.5,110.4,139,110.3z"
            />
            <g>
              <path
                className="st1"
                d="M126.4,124.2l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C126,124.2,126.1,124.3,126.4,124.2z"
              />
              <path
                className="st3"
                d="M131.5,121.2l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C131.1,121.2,131.3,121.3,131.5,121.2z"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            className="st0"
            d="M106.7,102.8L67.9,80.4c-0.9-0.5-1.6-1.8-1.6-3V70c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4c0.9,0.5,1.6,1.8,1.6,3
               v7.4C108.3,102.8,107.6,103.3,106.7,102.8z"
          />
          <path
            className="st7"
            d="M105.6,100.2L69,79.1c-0.4-0.3-0.8-0.9-0.8-1.5V72c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1c0.4,0.3,0.8,0.9,0.8,1.5
               v5.6C106.4,100.2,106,100.4,105.6,100.2z"
          />
          <path
            className="st2"
            d="M76.6,80.6l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C77.4,80.6,77,80.9,76.6,80.6z"
          />
          <path
            className="st5"
            d="M81.3,82.3c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C80.9,81,81.3,81.7,81.3,82.3z"
          />
          <path
            className="st5"
            d="M84.4,84.1c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C84,82.9,84.4,83.6,84.4,84.1z"
          />
          <path
            className="st2"
            d="M87.6,85.9c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C87.2,84.7,87.6,85.4,87.6,85.9z"
          />
          <path
            className="st5"
            d="M90.7,87.8c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5C90.3,86.5,90.7,87.2,90.7,87.8z"
          />
          <path
            className="st5"
            d="M104.1,97.1l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1v1.9
               C104.6,97.1,104.3,97.2,104.1,97.1z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M106.7,115.8L67.9,93.4c-0.9-0.5-1.6-1.8-1.6-3V83c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4c0.9,0.5,1.6,1.8,1.6,3
               v7.4C108.3,115.8,107.6,116.3,106.7,115.8z"
          />
          <path
            className="st7"
            d="M105.6,113.3L69,92.1c-0.4-0.3-0.8-0.9-0.8-1.5V85c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1c0.4,0.3,0.8,0.9,0.8,1.5
               v5.6C106.4,113.2,106,113.5,105.6,113.3z"
          />
          <path
            className="st2"
            d="M76.6,93.7l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C77.4,93.7,77,94,76.6,93.7z"
          />
          <path
            className="st3"
            d="M81.3,95.4c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5C80.9,94.1,81.3,94.8,81.3,95.4z"
          />
          <path
            className="st5"
            d="M84.4,97.2c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C84,95.9,84.4,96.6,84.4,97.2z"
          />
          <path
            className="st2"
            d="M87.6,99c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5C87.2,97.7,87.6,98.4,87.6,99z
               "
          />
          <path
            className="st5"
            d="M90.7,100.8c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C90.3,99.6,90.7,100.3,90.7,100.8z"
          />
          <path
            className="st5"
            d="M104.1,110.2l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1
               v1.9C104.6,110.1,104.3,110.3,104.1,110.2z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M106.7,128.9l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3v-7.4c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4
               c0.9,0.5,1.6,1.8,1.6,3v7.4C108.3,128.9,107.6,129.4,106.7,128.9z"
          />
          <path
            className="st7"
            d="M105.6,126.3L69,105.2c-0.4-0.3-0.8-0.9-0.8-1.5v-5.6c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C106.4,126.3,106,126.6,105.6,126.3z"
          />
          <path
            className="st2"
            d="M76.6,106.8l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C77.4,106.7,77,107,76.6,106.8z"
          />
          <path
            className="st3"
            d="M81.3,108.4c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C80.9,107.2,81.3,107.8,81.3,108.4z"
          />
          <path
            className="st5"
            d="M84.4,110.2c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5C84,109,84.4,109.7,84.4,110.2z"
          />
          <path
            className="st2"
            d="M87.6,112.1c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C87.2,110.8,87.6,111.5,87.6,112.1z"
          />
          <path
            className="st5"
            d="M90.7,113.9c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C90.3,112.6,90.7,113.3,90.7,113.9z"
          />
          <path
            className="st5"
            d="M104.1,123.2l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1
               v1.9C104.6,123.2,104.3,123.4,104.1,123.2z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M106.7,142l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3v-7.4c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4c0.9,0.5,1.6,1.8,1.6,3
               v7.4C108.3,141.9,107.6,142.5,106.7,142z"
          />
          <path
            className="st7"
            d="M105.6,139.4L69,118.2c-0.4-0.3-0.8-0.9-0.8-1.5v-5.6c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C106.4,139.4,106,139.6,105.6,139.4z"
          />
          <path
            className="st2"
            d="M76.6,119.8l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C77.4,119.8,77,120.1,76.6,119.8z"
          />
          <path
            className="st5"
            d="M81.3,121.5c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C80.9,120.2,81.3,120.9,81.3,121.5z"
          />
          <path
            className="st5"
            d="M84.4,123.3c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5C84,122,84.4,122.7,84.4,123.3z"
          />
          <path
            className="st2"
            d="M87.6,125.1c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C87.2,123.9,87.6,124.6,87.6,125.1z"
          />
          <path
            className="st5"
            d="M90.7,126.9c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C90.3,125.7,90.7,126.4,90.7,126.9z"
          />
          <path
            className="st2"
            d="M104.1,136.3l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1
               v1.9C104.6,136.3,104.3,136.4,104.1,136.3z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M106.7,155l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3v-7.4c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4c0.9,0.5,1.6,1.8,1.6,3
               v7.4C108.3,155,107.6,155.5,106.7,155z"
          />
          <path
            className="st7"
            d="M105.6,152.4L69,131.3c-0.4-0.3-0.8-0.9-0.8-1.5v-5.6c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C106.4,152.4,106,152.7,105.6,152.4z"
          />
          <path
            className="st2"
            d="M76.6,132.9l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C77.4,132.9,77,133.1,76.6,132.9z"
          />
          <path
            className="st5"
            d="M81.3,134.5c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5C80.9,133.3,81.3,134,81.3,134.5z
               "
          />
          <path
            className="st3"
            d="M84.4,136.4c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5C84,135.1,84.4,135.8,84.4,136.4z
               "
          />
          <path
            className="st2"
            d="M87.6,138.2c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C87.2,136.9,87.6,137.6,87.6,138.2z"
          />
          <path
            className="st3"
            d="M90.7,140c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5C90.3,138.7,90.7,139.4,90.7,140z"
          />
          <path
            className="st2"
            d="M104.1,149.3l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1
               v1.9C104.6,149.3,104.3,149.5,104.1,149.3z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M106.7,168.1l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3v-7.4c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4
               c0.9,0.5,1.6,1.8,1.6,3v7.4C108.3,168.1,107.6,168.6,106.7,168.1z"
          />
          <path
            className="st7"
            d="M105.6,165.5L69,144.4c-0.4-0.3-0.8-0.9-0.8-1.5v-5.6c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C106.4,165.5,106,165.7,105.6,165.5z"
          />
          <path
            className="st2"
            d="M76.6,145.9l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C77.4,145.9,77,146.2,76.6,145.9z"
          />
          <path
            className="st5"
            d="M81.3,147.6c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C80.9,146.3,81.3,147,81.3,147.6z"
          />
          <path
            className="st5"
            d="M84.4,149.4c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5C84,148.2,84.4,148.8,84.4,149.4z
               "
          />
          <path
            className="st2"
            d="M87.6,151.2c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5C87.2,150,87.6,150.7,87.6,151.2z
               "
          />
          <path
            className="st5"
            d="M90.7,153.1c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C90.3,151.8,90.7,152.5,90.7,153.1z"
          />
          <path
            className="st5"
            d="M104.1,162.4l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1
               v1.9C104.6,162.4,104.3,162.5,104.1,162.4z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M106.7,181.1l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3v-7.4c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4
               c0.9,0.5,1.6,1.8,1.6,3v7.4C108.3,181.1,107.6,181.6,106.7,181.1z"
          />
          <path
            className="st7"
            d="M105.6,178.5L69,157.4c-0.4-0.3-0.8-0.9-0.8-1.5v-5.6c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C106.4,178.5,106,178.8,105.6,178.5z"
          />
          <path
            className="st2"
            d="M76.6,159l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C77.4,159,77,159.2,76.6,159z"
          />
          <path
            className="st3"
            d="M82.3,161.4c0,1.1-0.8,1.5-1.7,1c-0.9-0.5-1.7-1.9-1.7-2.9c0-1.1,0.8-1.5,1.7-1
               C81.5,159,82.3,160.3,82.3,161.4z"
          />
          <path
            className="st5"
            d="M88.8,165.2c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C88.4,163.9,88.8,164.6,88.8,165.2z"
          />
          <path
            className="st2"
            d="M92,167c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5C91.6,165.7,92,166.4,92,167z"
          />
          <path
            className="st5"
            d="M95.1,168.8c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C94.7,167.6,95.1,168.3,95.1,168.8z"
          />
          <path
            className="st2"
            d="M104.1,175.4l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1
               v1.9C104.6,175.4,104.3,175.6,104.1,175.4z"
          />
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st4"
              d="M172,230.8l-41.5-23.6c-1.5-0.9-1.3-2.4,0.4-3.4l23.9-13.8c1.8-1,4.4-1.1,5.9-0.2l41.5,23.6
                  c1.5,0.9,1.3,2.4-0.4,3.4l-23.9,13.8C176.2,231.6,173.5,231.7,172,230.8z"
            />
            <path
              className="st1"
              d="M205.9,205.5L205.9,205.5v-96.8l-38.5-10.1l-7.1-4.1c-1.6-0.9-4.5-0.8-6.3,0.3l-6.6,3.8l-20.5,0L133,192
                  c0,0.6,0.3,1.2,1.1,1.7l38.4,29c1.6,0.9,4.5,0.8,6.3-0.3l25.6-14.8C205.5,207,206,206.2,205.9,205.5z"
            />
            <path
              className="st2"
              d="M157.4,93.9c-1.2,0-2.4,0.3-3.4,0.9l-6.6,3.8l-20.5,0v97c0,0.6,0.3,1.2,1.1,1.7l44.5,25.4
                  c0.8,0.5,1.8,0.7,2.9,0.6l0.5-97.6L157.4,93.9z"
            />
            <path
              className="st0"
              d="M172.8,222.9c1.4,0.6,3.4,0.6,5.1-0.1v-98.3h-5.1V222.9z"
            />
            <path
              className="st2"
              d="M172.4,125.7L128,100.3c-1.6-0.9-1.4-2.6,0.5-3.7L154,81.9c1.9-1.1,4.7-1.2,6.3-0.3l44.5,25.4
                  c1.6,0.9,1.4,2.6-0.5,3.7l-25.6,14.8C176.9,126.5,174.1,126.6,172.4,125.7z"
            />
            <path
              className="st6"
              d="M204.4,108.8l-25.6,14.8c-1.9,1.1-4.7,1.2-6.3,0.3L128,98.5c-0.4-0.2-0.6-0.5-0.8-0.7
                  c-0.6,0.9-0.3,1.9,0.8,2.5l44.5,25.4c1.6,0.9,4.5,0.8,6.3-0.3l25.6-14.8c1.5-0.8,1.9-2,1.3-2.9
                  C205.4,108.1,205,108.5,204.4,108.8z"
            />
            <g>
              <path
                className="st5"
                d="M173.7,115.4l-29.6-17.2c-0.8-0.4-0.7-1.2,0.2-1.7l12-6.9c0.9-0.5,2.2-0.6,3-0.1l29.6,17.2
                     c0.8,0.4,0.7,1.2-0.2,1.7l-12,6.9C175.8,115.8,174.5,115.8,173.7,115.4z"
              />
              <g>
                <path
                  className="st2"
                  d="M157.7,99l-1.7-1c-0.1-0.1-0.1-0.2,0-0.2l1.7-1c0.1-0.1,0.3-0.1,0.4,0l1.7,1c0.1,0.1,0.1,0.2,0,0.2
                        l-1.7,1C158,99.1,157.8,99.1,157.7,99z"
                />
                <path
                  className="st2"
                  d="M163.4,102.3l-1.7-1c-0.1-0.1-0.1-0.2,0-0.2l1.7-1c0.1-0.1,0.3-0.1,0.4,0l1.7,1c0.1,0.1,0.1,0.2,0,0.2
                        l-1.7,1C163.7,102.4,163.6,102.4,163.4,102.3z"
                />
                <path
                  className="st2"
                  d="M169.2,105.6l-1.7-1c-0.1-0.1-0.1-0.2,0-0.2l1.7-1c0.1-0.1,0.3-0.1,0.4,0l1.7,1c0.1,0.1,0.1,0.2,0,0.2
                        l-1.7,1C169.5,105.6,169.3,105.7,169.2,105.6z"
                />
                <path
                  className="st2"
                  d="M174.9,108.9l-1.7-1c-0.1-0.1-0.1-0.2,0-0.2l1.7-1c0.1-0.1,0.3-0.1,0.4,0l1.7,1c0.1,0.1,0.1,0.2,0,0.2
                        l-1.7,1C175.2,108.9,175,109,174.9,108.9z"
                />
              </g>
              <path
                className="st0"
                d="M144.1,98.2L144.1,98.2c0.1,0,0.1-0.1,0.2-0.1l12-6.9c0.9-0.5,2.2-0.6,3-0.1l29.6,17.1
                     c0.7-0.5,0.7-1.2,0-1.6l-29.6-17.2c-0.8-0.4-2.1-0.4-3,0.1l-12,6.9C143.4,97,143.3,97.8,144.1,98.2z"
              />
            </g>
          </g>
          <g>
            <path
              className="st5"
              d="M183.8,144.8l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6c0-1.3-0.8-1.8-1.7-1.3l-16.8,9.6c-0.9,0.5-1.7,2-1.7,3.3v6
                  C182.1,144.8,182.8,145.4,183.8,144.8z"
            />
            <path
              className="st3"
              d="M201.5,124.4c0.3,0.2,0.4,0.7,0.4,1.3v6c0,1.3-0.8,2.7-1.7,3.3l-16.8,9.6c-0.3,0.2-0.6,0.2-0.8,0.2
                  c0.3,0.3,0.8,0.3,1.3,0l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6C202.3,125.1,202,124.5,201.5,124.4z"
            />
            <g>
              <path
                className="st1"
                d="M188.8,138.3l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C188.5,138.3,188.6,138.4,188.8,138.3z"
              />
              <path
                className="st3"
                d="M194,135.3l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C193.6,135.3,193.8,135.4,194,135.3z"
              />
            </g>
          </g>
          <g>
            <path
              className="st5"
              d="M183.8,188.3l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6c0-1.3-0.8-1.8-1.7-1.3l-16.8,9.6c-0.9,0.5-1.7,2-1.7,3.3v6
                  C182.1,188.3,182.8,188.9,183.8,188.3z"
            />
            <path
              className="st3"
              d="M201.5,167.9c0.3,0.2,0.4,0.7,0.4,1.3v6c0,1.3-0.8,2.7-1.7,3.3l-16.8,9.6c-0.3,0.2-0.6,0.2-0.8,0.2
                  c0.3,0.3,0.8,0.3,1.3,0l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6C202.3,168.6,202,168,201.5,167.9z"
            />
            <g>
              <path
                className="st1"
                d="M188.8,181.8l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C188.5,181.8,188.6,181.9,188.8,181.8z"
              />
              <path
                className="st3"
                d="M194,178.8l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C193.6,178.8,193.8,178.9,194,178.8z"
              />
            </g>
          </g>
          <g>
            <path
              className="st5"
              d="M183.8,210.1l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6c0-1.3-0.8-1.8-1.7-1.3l-16.8,9.6c-0.9,0.5-1.7,2-1.7,3.3v6
                  C182.1,210.1,182.8,210.6,183.8,210.1z"
            />
            <path
              className="st3"
              d="M201.5,189.7c0.3,0.2,0.4,0.7,0.4,1.3v6c0,1.3-0.8,2.7-1.7,3.3l-16.8,9.6c-0.3,0.2-0.6,0.2-0.8,0.2
                  c0.3,0.3,0.8,0.3,1.3,0l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6C202.3,190.3,202,189.8,201.5,189.7z"
            />
            <g>
              <path
                className="st1"
                d="M188.8,203.6l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C188.5,203.6,188.6,203.7,188.8,203.6z"
              />
              <path
                className="st3"
                d="M194,200.6l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C193.6,200.6,193.8,200.7,194,200.6z"
              />
            </g>
          </g>
          <g>
            <path
              className="st5"
              d="M183.8,166.6l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6c0-1.3-0.8-1.8-1.7-1.3l-16.8,9.6c-0.9,0.5-1.7,2-1.7,3.3v6
                  C182.1,166.6,182.8,167.1,183.8,166.6z"
            />
            <path
              className="st3"
              d="M201.5,146.2c0.3,0.2,0.4,0.7,0.4,1.3v6c0,1.3-0.8,2.7-1.7,3.3l-16.8,9.6c-0.3,0.2-0.6,0.2-0.8,0.2
                  c0.3,0.3,0.8,0.3,1.3,0l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6C202.3,146.8,202,146.3,201.5,146.2z"
            />
            <g>
              <path
                className="st1"
                d="M188.8,160.1l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C188.5,160.1,188.6,160.2,188.8,160.1z"
              />
              <path
                className="st3"
                d="M194,157.1l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C193.6,157.1,193.8,157.2,194,157.1z"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            className="st0"
            d="M169.2,138.7l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3v-7.4c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4
               c0.9,0.5,1.6,1.8,1.6,3v7.4C170.8,138.7,170.1,139.2,169.2,138.7z"
          />
          <path
            className="st7"
            d="M168.1,136.1L131.5,115c-0.4-0.3-0.8-0.9-0.8-1.5v-5.6c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C168.9,136.1,168.5,136.3,168.1,136.1z"
          />
          <path
            className="st2"
            d="M139.1,116.5l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C139.9,116.5,139.5,116.8,139.1,116.5z"
          />
          <path
            className="st5"
            d="M143.8,118.2c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C143.4,116.9,143.8,117.6,143.8,118.2z"
          />
          <path
            className="st5"
            d="M146.9,120c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C146.5,118.8,146.9,119.4,146.9,120z"
          />
          <path
            className="st2"
            d="M150.1,121.8c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C149.7,120.6,150.1,121.3,150.1,121.8z"
          />
          <path
            className="st5"
            d="M153.2,123.7c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C152.8,122.4,153.2,123.1,153.2,123.7z"
          />
          <path
            className="st5"
            d="M166.5,133l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1V129c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1v1.9
               C167,133,166.8,133.1,166.5,133z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M169.2,151.7l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3v-7.4c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4
               c0.9,0.5,1.6,1.8,1.6,3v7.4C170.8,151.7,170.1,152.2,169.2,151.7z"
          />
          <path
            className="st7"
            d="M168.1,149.1L131.5,128c-0.4-0.3-0.8-0.9-0.8-1.5v-5.6c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C168.9,149.1,168.5,149.4,168.1,149.1z"
          />
          <path
            className="st2"
            d="M139.1,129.6l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C139.9,129.6,139.5,129.8,139.1,129.6z"
          />
          <path
            className="st3"
            d="M143.8,131.3c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C143.4,130,143.8,130.7,143.8,131.3z"
          />
          <path
            className="st5"
            d="M146.9,133.1c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C146.5,131.8,146.9,132.5,146.9,133.1z"
          />
          <path
            className="st2"
            d="M150.1,134.9c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C149.7,133.6,150.1,134.3,150.1,134.9z"
          />
          <path
            className="st5"
            d="M153.2,136.7c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C152.8,135.5,153.2,136.2,153.2,136.7z"
          />
          <path
            className="st5"
            d="M166.5,146l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1v1.9
               C167,146,166.8,146.2,166.5,146z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M169.2,164.8l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3V132c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4c0.9,0.5,1.6,1.8,1.6,3
               v7.4C170.8,164.8,170.1,165.3,169.2,164.8z"
          />
          <path
            className="st7"
            d="M168.1,162.2l-36.6-21.1c-0.4-0.3-0.8-0.9-0.8-1.5V134c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C168.9,162.2,168.5,162.5,168.1,162.2z"
          />
          <path
            className="st2"
            d="M139.1,142.7l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C139.9,142.6,139.5,142.9,139.1,142.7z"
          />
          <path
            className="st3"
            d="M143.8,144.3c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C143.4,143,143.8,143.7,143.8,144.3z"
          />
          <path
            className="st5"
            d="M146.9,146.1c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C146.5,144.9,146.9,145.6,146.9,146.1z"
          />
          <path
            className="st2"
            d="M150.1,148c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C149.7,146.7,150.1,147.4,150.1,148z"
          />
          <path
            className="st5"
            d="M153.2,149.8c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C152.8,148.5,153.2,149.2,153.2,149.8z"
          />
          <path
            className="st5"
            d="M166.5,159.1l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1
               v1.9C167,159.1,166.8,159.3,166.5,159.1z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M169.2,177.9l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3V145c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4c0.9,0.5,1.6,1.8,1.6,3
               v7.4C170.8,177.8,170.1,178.4,169.2,177.9z"
          />
          <path
            className="st7"
            d="M168.1,175.3l-36.6-21.1c-0.4-0.3-0.8-0.9-0.8-1.5V147c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C168.9,175.2,168.5,175.5,168.1,175.3z"
          />
          <path
            className="st2"
            d="M139.1,155.7l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C139.9,155.7,139.5,156,139.1,155.7z"
          />
          <path
            className="st5"
            d="M143.8,157.4c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C143.4,156.1,143.8,156.8,143.8,157.4z"
          />
          <path
            className="st5"
            d="M146.9,159.2c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C146.5,157.9,146.9,158.6,146.9,159.2z"
          />
          <path
            className="st2"
            d="M150.1,161c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C149.7,159.8,150.1,160.4,150.1,161z"
          />
          <path
            className="st5"
            d="M153.2,162.8c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C152.8,161.6,153.2,162.3,153.2,162.8z"
          />
          <path
            className="st2"
            d="M166.5,172.2l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1
               v1.9C167,172.2,166.8,172.3,166.5,172.2z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M169.2,190.9l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3v-7.4c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4
               c0.9,0.5,1.6,1.8,1.6,3v7.4C170.8,190.9,170.1,191.4,169.2,190.9z"
          />
          <path
            className="st7"
            d="M168.1,188.3l-36.6-21.1c-0.4-0.3-0.8-0.9-0.8-1.5v-5.6c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C168.9,188.3,168.5,188.6,168.1,188.3z"
          />
          <path
            className="st2"
            d="M139.1,168.8l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C139.9,168.8,139.5,169,139.1,168.8z"
          />
          <path
            className="st5"
            d="M143.8,170.4c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C143.4,169.2,143.8,169.9,143.8,170.4z"
          />
          <path
            className="st3"
            d="M146.9,172.3c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C146.5,171,146.9,171.7,146.9,172.3z"
          />
          <path
            className="st2"
            d="M150.1,174.1c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C149.7,172.8,150.1,173.5,150.1,174.1z"
          />
          <path
            className="st3"
            d="M153.2,175.9c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C152.8,174.6,153.2,175.3,153.2,175.9z"
          />
          <path
            className="st2"
            d="M166.5,185.2l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1
               v1.9C167,185.2,166.8,185.4,166.5,185.2z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M169.2,204l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3v-7.4c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4c0.9,0.5,1.6,1.8,1.6,3
               v7.4C170.8,203.9,170.1,204.5,169.2,204z"
          />
          <path
            className="st7"
            d="M168.1,201.4l-36.6-21.1c-0.4-0.3-0.8-0.9-0.8-1.5v-5.6c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C168.9,201.4,168.5,201.6,168.1,201.4z"
          />
          <path
            className="st2"
            d="M139.1,181.8l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               v0C139.9,181.8,139.5,182.1,139.1,181.8z"
          />
          <path
            className="st5"
            d="M143.8,183.5c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C143.4,182.2,143.8,182.9,143.8,183.5z"
          />
          <path
            className="st5"
            d="M146.9,185.3c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C146.5,184.1,146.9,184.7,146.9,185.3z"
          />
          <path
            className="st2"
            d="M150.1,187.1c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C149.7,185.9,150.1,186.6,150.1,187.1z"
          />
          <path
            className="st5"
            d="M153.2,189c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C152.8,187.7,153.2,188.4,153.2,189z"
          />
          <path
            className="st5"
            d="M166.5,198.3l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1
               v1.9C167,198.3,166.8,198.4,166.5,198.3z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M169.2,217l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3v-7.4c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4c0.9,0.5,1.6,1.8,1.6,3
               v7.4C170.8,217,170.1,217.5,169.2,217z"
          />
          <path
            className="st7"
            d="M168.1,214.4l-36.6-21.1c-0.4-0.3-0.8-0.9-0.8-1.5v-5.6c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C168.9,214.4,168.5,214.7,168.1,214.4z"
          />
          <path
            className="st2"
            d="M139.1,194.9l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C139.9,194.9,139.5,195.1,139.1,194.9z"
          />
          <path
            className="st3"
            d="M144.8,197.3c0,1.1-0.8,1.5-1.7,1c-0.9-0.5-1.7-1.9-1.7-2.9c0-1.1,0.8-1.5,1.7-1
               C144,194.9,144.8,196.2,144.8,197.3z"
          />
          <path
            className="st5"
            d="M151.3,201.1c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C150.9,199.8,151.3,200.5,151.3,201.1z"
          />
          <path
            className="st2"
            d="M154.5,202.9c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C154.1,201.6,154.5,202.3,154.5,202.9z"
          />
          <path
            className="st5"
            d="M157.6,204.7c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C157.2,203.5,157.6,204.2,157.6,204.7z"
          />
          <path
            className="st2"
            d="M166.5,211.3l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1
               v1.9C167,211.3,166.8,211.5,166.5,211.3z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="st2"
            d="M213.8,102.2l-1.7-1v-2.4c0-2.6-1.8-5.7-4.1-7l-12.7-7.3c-2.2-1.3-4.1-0.2-4.1,2.3v26l-1.7-24.5v-2.4
               c0-3.6,2.6-5.1,5.7-3.3l12.7,7.3c3.2,1.8,5.7,6.3,5.7,9.9V102.2z"
          />
        </g>
        <path
          className="st2"
          d="M208.1,105.5l5.7-3.3l-1.2-4l-1.6-4c0,0-2.8-3.1-3-3.1c-0.2-0.1-7.7-4.3-7.7-4.3l-6.2-4.5l-3,0.1l-5.5,3.2
            l4,1.6c0,0,2.3,1.1,2.8,1.3c0.5,0.1,2.7,2.2,2.8,2.3c0,0,2.7,1.1,2.7,1.1l0.6-0.1l4.9,3.4l1.6,0.7l0.8,1.4l1.3,3.6L208.1,105.5z"
        />
        <path
          className="st1"
          d="M191.3,112.9l-5.7,3.3c0,0-0.4-27.2-0.4-27.3c0,0,1.3-1.8,1.3-1.8l3.1-0.6l2.5,1.9l-0.8-0.3l-0.3,0.2l0.4,0.6
            V112.9z"
        />
        <g>
          <path
            className="st0"
            d="M208.1,105.5l-1.7-1v-2.4c0-2.6-1.8-5.7-4.1-7l-12.7-7.3c-2.2-1.3-4.1-0.2-4.1,2.3v26l-1.7-1v-26
               c0-3.6,2.6-5.1,5.7-3.3l12.7,7.3c3.2,1.8,5.7,6.3,5.7,9.9V105.5z"
          />
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st4"
              d="M232.8,259.9l-29.1-16.6c-1.1-0.6-0.9-1.7,0.3-2.4l16.7-9.7c1.2-0.7,3.1-0.8,4.1-0.2l29.1,16.6
                  c1.1,0.6,0.9,1.7-0.3,2.4l-16.7,9.7C235.8,260.4,233.9,260.5,232.8,259.9z"
            />
            <path
              className="st1"
              d="M268.4,217.7L268.4,217.7V121l-38.5-10.1l-7.1-4.1c-1.6-0.9-4.5-0.8-6.3,0.3l-6.6,3.8l-20.5,0l6.1,93.4
                  c0,0.6,0.3,1.2,1.1,1.7l38.4,29c1.6,0.9,4.5,0.8,6.3-0.3l25.6-14.8C268,219.3,268.5,218.5,268.4,217.7z"
            />
            <path
              className="st2"
              d="M219.9,106.2c-1.2,0-2.4,0.3-3.4,0.9l-6.6,3.8l-20.5,0v97c0,0.6,0.3,1.2,1.1,1.7l44.5,25.4
                  c0.8,0.5,1.8,0.7,2.9,0.6l0.5-97.6L219.9,106.2z"
            />
            <path
              className="st0"
              d="M235.3,235.2c1.4,0.6,3.4,0.6,5.1-0.1v-98.3h-5.1V235.2z"
            />
            <path
              className="st2"
              d="M234.9,138l-44.5-25.4c-1.6-0.9-1.4-2.6,0.5-3.7l25.6-14.8c1.9-1.1,4.7-1.2,6.3-0.3l44.5,25.4
                  c1.6,0.9,1.4,2.6-0.5,3.7l-25.6,14.8C239.4,138.8,236.6,138.9,234.9,138z"
            />
            <path
              className="st6"
              d="M266.9,121.1l-25.6,14.8c-1.9,1.1-4.7,1.2-6.3,0.3l-44.5-25.4c-0.4-0.2-0.6-0.5-0.8-0.7
                  c-0.6,0.9-0.3,1.9,0.8,2.5l44.5,25.4c1.6,0.9,4.5,0.8,6.3-0.3l25.6-14.8c1.5-0.8,1.9-2,1.3-2.9
                  C267.9,120.4,267.5,120.8,266.9,121.1z"
            />
            <g>
              <path
                className="st5"
                d="M236.2,127.7l-29.6-17.2c-0.8-0.4-0.7-1.2,0.2-1.7l12-6.9c0.9-0.5,2.2-0.6,3-0.1l29.6,17.2
                     c0.8,0.4,0.7,1.2-0.2,1.7l-12,6.9C238.3,128.1,237,128.1,236.2,127.7z"
              />
              <g>
                <path
                  className="st2"
                  d="M220.2,111.3l-1.7-1c-0.1-0.1-0.1-0.2,0-0.2l1.7-1c0.1-0.1,0.3-0.1,0.4,0l1.7,1c0.1,0.1,0.1,0.2,0,0.2
                        l-1.7,1C220.5,111.3,220.3,111.3,220.2,111.3z"
                />
                <path
                  className="st2"
                  d="M225.9,114.6l-1.7-1c-0.1-0.1-0.1-0.2,0-0.2l1.7-1c0.1-0.1,0.3-0.1,0.4,0l1.7,1c0.1,0.1,0.1,0.2,0,0.2
                        l-1.7,1C226.2,114.6,226,114.6,225.9,114.6z"
                />
                <path
                  className="st2"
                  d="M231.7,117.9l-1.7-1c-0.1-0.1-0.1-0.2,0-0.2l1.7-1c0.1-0.1,0.3-0.1,0.4,0l1.7,1c0.1,0.1,0.1,0.2,0,0.2
                        l-1.7,1C232,117.9,231.8,117.9,231.7,117.9z"
                />
                <path
                  className="st2"
                  d="M237.4,121.2l-1.7-1c-0.1-0.1-0.1-0.2,0-0.2l1.7-1c0.1-0.1,0.3-0.1,0.4,0l1.7,1c0.1,0.1,0.1,0.2,0,0.2
                        l-1.7,1C237.7,121.2,237.5,121.2,237.4,121.2z"
                />
              </g>
              <path
                className="st0"
                d="M206.6,110.5L206.6,110.5c0.1,0,0.1-0.1,0.2-0.1l12-6.9c0.9-0.5,2.2-0.6,3-0.1l29.6,17.1
                     c0.7-0.5,0.7-1.2,0-1.6l-29.6-17.2c-0.8-0.4-2.1-0.4-3,0.1l-12,6.9C205.9,109.3,205.8,110.1,206.6,110.5z"
              />
            </g>
          </g>
          <g>
            <path
              className="st5"
              d="M246.3,157.1l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6c0-1.3-0.8-1.8-1.7-1.3l-16.8,9.6c-0.9,0.5-1.7,2-1.7,3.3v6
                  C244.6,157.1,245.3,157.7,246.3,157.1z"
            />
            <path
              className="st3"
              d="M263.9,136.7c0.3,0.2,0.4,0.7,0.4,1.3v6c0,1.3-0.8,2.7-1.7,3.3l-16.8,9.6c-0.3,0.2-0.6,0.2-0.8,0.2
                  c0.3,0.3,0.8,0.3,1.3,0l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6C264.8,137.3,264.5,136.8,263.9,136.7z"
            />
            <g>
              <path
                className="st1"
                d="M251.3,150.6l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C251,150.6,251.1,150.7,251.3,150.6z"
              />
              <path
                className="st3"
                d="M256.5,147.6l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C256.1,147.6,256.3,147.7,256.5,147.6z"
              />
            </g>
          </g>
          <g>
            <path
              className="st5"
              d="M246.3,200.6l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6c0-1.3-0.8-1.8-1.7-1.3l-16.8,9.6c-0.9,0.5-1.7,2-1.7,3.3v6
                  C244.6,200.6,245.3,201.2,246.3,200.6z"
            />
            <path
              className="st3"
              d="M263.9,180.2c0.3,0.2,0.4,0.7,0.4,1.3v6c0,1.3-0.8,2.7-1.7,3.3l-16.8,9.6c-0.3,0.2-0.6,0.2-0.8,0.2
                  c0.3,0.3,0.8,0.3,1.3,0l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6C264.8,180.8,264.5,180.3,263.9,180.2z"
            />
            <g>
              <path
                className="st1"
                d="M251.3,194.1l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C251,194.1,251.1,194.2,251.3,194.1z"
              />
              <path
                className="st3"
                d="M256.5,191.1l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C256.1,191.1,256.3,191.2,256.5,191.1z"
              />
            </g>
          </g>
          <g>
            <path
              className="st5"
              d="M246.3,222.4l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6c0-1.3-0.8-1.8-1.7-1.3l-16.8,9.6c-0.9,0.5-1.7,2-1.7,3.3v6
                  C244.6,222.3,245.3,222.9,246.3,222.4z"
            />
            <path
              className="st3"
              d="M263.9,202c0.3,0.2,0.4,0.7,0.4,1.3v6c0,1.3-0.8,2.7-1.7,3.3l-16.8,9.6c-0.3,0.2-0.6,0.2-0.8,0.2
                  c0.3,0.3,0.8,0.3,1.3,0l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6C264.8,202.6,264.5,202.1,263.9,202z"
            />
            <g>
              <path
                className="st1"
                d="M251.3,215.8l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C251,215.8,251.1,216,251.3,215.8z"
              />
              <path
                className="st3"
                d="M256.5,212.9l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C256.1,212.9,256.3,213,256.5,212.9z"
              />
              <path
                className="st3"
                d="M255.4,224l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C255,224,255.1,224.1,255.4,224z"
              />
            </g>
          </g>
          <g>
            <path
              className="st5"
              d="M246.3,178.9l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6c0-1.3-0.8-1.8-1.7-1.3l-16.8,9.6c-0.9,0.5-1.7,2-1.7,3.3v6
                  C244.6,178.8,245.3,179.4,246.3,178.9z"
            />
            <path
              className="st3"
              d="M263.9,158.5c0.3,0.2,0.4,0.7,0.4,1.3v6c0,1.3-0.8,2.7-1.7,3.3l-16.8,9.6c-0.3,0.2-0.6,0.2-0.8,0.2
                  c0.3,0.3,0.8,0.3,1.3,0l16.8-9.6c0.9-0.5,1.7-2,1.7-3.3v-6C264.8,159.1,264.5,158.6,263.9,158.5z"
            />
            <g>
              <path
                className="st1"
                d="M251.3,172.3l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C251,172.3,251.1,172.5,251.3,172.3z"
              />
              <path
                className="st3"
                d="M256.5,169.4l1.5-0.9c0.2-0.1,0.4-0.4,0.4-0.7v-1.6c0-0.3-0.2-0.4-0.4-0.3l-1.5,0.9
                     c-0.2,0.1-0.4,0.4-0.4,0.7v1.6C256.1,169.4,256.3,169.5,256.5,169.4z"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            className="st0"
            d="M231.7,151l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3v-7.4c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4c0.9,0.5,1.6,1.8,1.6,3
               v7.4C233.3,150.9,232.6,151.5,231.7,151z"
          />
          <path
            className="st7"
            d="M230.6,148.4L194,127.2c-0.4-0.3-0.8-0.9-0.8-1.5v-5.6c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C231.4,148.4,231,148.6,230.6,148.4z"
          />
          <path
            className="st2"
            d="M201.6,128.8l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C202.4,128.8,202,129.1,201.6,128.8z"
          />
          <path
            className="st5"
            d="M206.3,130.5c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C205.9,129.2,206.3,129.9,206.3,130.5z"
          />
          <path
            className="st5"
            d="M209.4,132.3c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C209,131,209.4,131.7,209.4,132.3z"
          />
          <path
            className="st2"
            d="M212.6,134.1c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C212.2,132.9,212.6,133.6,212.6,134.1z"
          />
          <path
            className="st5"
            d="M215.7,135.9c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C215.3,134.7,215.7,135.4,215.7,135.9z"
          />
          <path
            className="st5"
            d="M229,145.3l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1v1.9
               C229.5,145.3,229.3,145.4,229,145.3z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M231.7,164l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3v-7.4c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4c0.9,0.5,1.6,1.8,1.6,3
               v7.4C233.3,164,232.6,164.5,231.7,164z"
          />
          <path
            className="st7"
            d="M230.6,161.4L194,140.3c-0.4-0.3-0.8-0.9-0.8-1.5v-5.6c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C231.4,161.4,231,161.7,230.6,161.4z"
          />
          <path
            className="st2"
            d="M201.6,141.9l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C202.4,141.9,202,142.1,201.6,141.9z"
          />
          <path
            className="st3"
            d="M206.3,143.5c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C205.9,142.3,206.3,143,206.3,143.5z"
          />
          <path
            className="st5"
            d="M209.4,145.4c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C209,144.1,209.4,144.8,209.4,145.4z"
          />
          <path
            className="st2"
            d="M212.6,147.2c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C212.2,145.9,212.6,146.6,212.6,147.2z"
          />
          <path
            className="st5"
            d="M215.7,149c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C215.3,147.7,215.7,148.4,215.7,149z"
          />
          <path
            className="st5"
            d="M229,158.3l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1v1.9
               C229.5,158.3,229.3,158.5,229,158.3z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M231.7,177.1l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3v-7.4c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4
               c0.9,0.5,1.6,1.8,1.6,3v7.4C233.3,177.1,232.6,177.6,231.7,177.1z"
          />
          <path
            className="st7"
            d="M230.6,174.5L194,153.4c-0.4-0.3-0.8-0.9-0.8-1.5v-5.6c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C231.4,174.5,231,174.7,230.6,174.5z"
          />
          <path
            className="st2"
            d="M201.6,154.9l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C202.4,154.9,202,155.2,201.6,154.9z"
          />
          <path
            className="st3"
            d="M206.3,156.6c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C205.9,155.3,206.3,156,206.3,156.6z"
          />
          <path
            className="st5"
            d="M209.4,158.4c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C209,157.2,209.4,157.8,209.4,158.4z"
          />
          <path
            className="st2"
            d="M212.6,160.2c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C212.2,159,212.6,159.7,212.6,160.2z"
          />
          <path
            className="st5"
            d="M215.7,162.1c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C215.3,160.8,215.7,161.5,215.7,162.1z"
          />
          <path
            className="st5"
            d="M229,171.4l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1v1.9
               C229.5,171.4,229.3,171.5,229,171.4z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M231.7,190.1l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3v-7.4c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4
               c0.9,0.5,1.6,1.8,1.6,3v7.4C233.3,190.1,232.6,190.6,231.7,190.1z"
          />
          <path
            className="st7"
            d="M230.6,187.5L194,166.4c-0.4-0.3-0.8-0.9-0.8-1.5v-5.6c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C231.4,187.5,231,187.8,230.6,187.5z"
          />
          <path
            className="st2"
            d="M201.6,168l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C202.4,168,202,168.2,201.6,168z"
          />
          <path
            className="st5"
            d="M206.3,169.7c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C205.9,168.4,206.3,169.1,206.3,169.7z"
          />
          <path
            className="st5"
            d="M209.4,171.5c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C209,170.2,209.4,170.9,209.4,171.5z"
          />
          <path
            className="st2"
            d="M212.6,173.3c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C212.2,172,212.6,172.7,212.6,173.3z"
          />
          <path
            className="st5"
            d="M215.7,175.1c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C215.3,173.9,215.7,174.6,215.7,175.1z"
          />
          <path
            className="st2"
            d="M229,184.4l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1v1.9
               C229.5,184.4,229.3,184.6,229,184.4z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M231.7,203.2l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3v-7.4c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4
               c0.9,0.5,1.6,1.8,1.6,3v7.4C233.3,203.2,232.6,203.7,231.7,203.2z"
          />
          <path
            className="st7"
            d="M230.6,200.6L194,179.5c-0.4-0.3-0.8-0.9-0.8-1.5v-5.6c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C231.4,200.6,231,200.9,230.6,200.6z"
          />
          <path
            className="st2"
            d="M201.6,181.1l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C202.4,181,202,181.3,201.6,181.1z"
          />
          <path
            className="st5"
            d="M206.3,182.7c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C205.9,181.4,206.3,182.1,206.3,182.7z"
          />
          <path
            className="st3"
            d="M209.4,184.5c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C209,183.3,209.4,184,209.4,184.5z"
          />
          <path
            className="st2"
            d="M212.6,186.4c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C212.2,185.1,212.6,185.8,212.6,186.4z"
          />
          <path
            className="st3"
            d="M215.7,188.2c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C215.3,186.9,215.7,187.6,215.7,188.2z"
          />
          <path
            className="st2"
            d="M229,197.5l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1v1.9
               C229.5,197.5,229.3,197.7,229,197.5z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M231.7,216.3l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3v-7.4c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4
               c0.9,0.5,1.6,1.8,1.6,3v7.4C233.3,216.2,232.6,216.8,231.7,216.3z"
          />
          <path
            className="st7"
            d="M230.6,213.7L194,192.5c-0.4-0.3-0.8-0.9-0.8-1.5v-5.6c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C231.4,213.6,231,213.9,230.6,213.7z"
          />
          <path
            className="st2"
            d="M201.6,194.1l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C202.4,194.1,202,194.4,201.6,194.1z"
          />
          <path
            className="st5"
            d="M206.3,195.8c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C205.9,194.5,206.3,195.2,206.3,195.8z"
          />
          <path
            className="st5"
            d="M209.4,197.6c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C209,196.3,209.4,197,209.4,197.6z"
          />
          <path
            className="st2"
            d="M212.6,199.4c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C212.2,198.2,212.6,198.9,212.6,199.4z"
          />
          <path
            className="st5"
            d="M215.7,201.2c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C215.3,200,215.7,200.7,215.7,201.2z"
          />
          <path
            className="st5"
            d="M229,210.6l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1v1.9
               C229.5,210.6,229.3,210.7,229,210.6z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M231.7,229.3l-38.8-22.4c-0.9-0.5-1.6-1.8-1.6-3v-7.4c0-1.2,0.7-1.7,1.6-1.2l38.8,22.4
               c0.9,0.5,1.6,1.8,1.6,3v7.4C233.3,229.3,232.6,229.8,231.7,229.3z"
          />
          <path
            className="st7"
            d="M230.6,226.7L194,205.6c-0.4-0.3-0.8-0.9-0.8-1.5v-5.6c0-0.6,0.4-0.9,0.8-0.6l36.6,21.1
               c0.4,0.3,0.8,0.9,0.8,1.5v5.6C231.4,226.7,231,227,230.6,226.7z"
          />
          <path
            className="st2"
            d="M201.6,207.2l-5.5-3.2c-0.4-0.2-0.8-0.9-0.8-1.5l0,0c0-0.6,0.3-0.8,0.8-0.6l5.5,3.2c0.4,0.2,0.8,0.9,0.8,1.5
               l0,0C202.4,207.2,202,207.4,201.6,207.2z"
          />
          <path
            className="st3"
            d="M207.3,209.5c0,1.1-0.8,1.5-1.7,1c-0.9-0.5-1.7-1.9-1.7-2.9s0.8-1.5,1.7-1
               C206.5,207.1,207.3,208.5,207.3,209.5z"
          />
          <path
            className="st5"
            d="M213.8,213.4c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C213.4,212.1,213.8,212.8,213.8,213.4z"
          />
          <path
            className="st2"
            d="M217,215.2c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6c0-0.6,0.4-0.8,0.9-0.5
               C216.6,213.9,217,214.6,217,215.2z"
          />
          <path
            className="st5"
            d="M220.1,217c0,0.6-0.4,0.8-0.9,0.5c-0.5-0.3-0.9-1-0.9-1.6s0.4-0.8,0.9-0.5
               C219.7,215.8,220.1,216.4,220.1,217z"
          />
          <path
            className="st2"
            d="M229,223.6l-1.8-1.1c-0.3-0.2-0.5-0.6-0.5-1v-1.9c0-0.4,0.2-0.5,0.5-0.4l1.8,1.1c0.3,0.2,0.5,0.6,0.5,1v1.9
               C229.5,223.6,229.3,223.8,229,223.6z"
          />
        </g>
      </g>
      <g>
        <path
          className="st4"
          d="M98,298.2l-86.6-50c-2.4-1.4-2.1-3.9,0.7-5.5l58.9-34c2.8-1.6,7.1-1.8,9.6-0.4l86.6,50
            c2.4,1.4,2.1,3.9-0.7,5.5l-58.9,34C104.7,299.4,100.4,299.6,98,298.2z"
        />
        <path
          className="st5"
          d="M187.4,169.7l-86.6-50c-1-0.6-2-0.7-2.9-0.4l0,0l-0.2,0.1c0,0,0,0,0,0l-3.1,1.2l2.8,1.4c0,0.1,0,0.1,0,0.2
            l-14.1,64.2c-0.7,3.2,0.7,7,3.2,8.4l84.5,48.8l-0.3,3.6l3.4-1.3c1.1-0.5,2.1-1.8,2.4-3.5l14.1-64.2
            C191.3,174.9,189.9,171.1,187.4,169.7z"
        />
        <path
          className="st1"
          d="M187.4,169.7l-86.6-50c-1-0.6-2-0.7-2.9-0.4l0,0l-0.2,0.1c0,0,0,0,0,0l-3.1,1.2l2.8,1.4c0,0.1,0,0.1,0,0.2
            l-9.3,42.3l42.4,33l59.7-24.3C189.6,171.7,188.6,170.4,187.4,169.7z"
        />
        <path
          className="st8"
          d="M184.2,179.6l6.6-2.8c0.1-2.6-0.9-5.2-2.7-6.6l-10.4,4.4L184.2,179.6z"
        />
        <path
          className="st2"
          d="M167.6,246.7l-86.6-50c-2.4-1.4-3.9-5.2-3.2-8.4L92,124.2c0.7-3.2,3.3-4.7,5.7-3.3l86.6,50
            c2.4,1.4,3.9,5.2,3.2,8.4l-14.1,64.2C172.6,246.7,170.1,248.2,167.6,246.7z"
        />
        <path
          className="st3"
          d="M185.1,170.6l-86.6-50c-1.2-0.7-2.4-0.7-3.4-0.1c0.8-0.2,1.7-0.1,2.6,0.5l86.6,50c2.4,1.4,3.9,5.2,3.2,8.4
            l-14.1,64.2c-0.4,1.7-1.2,2.8-2.3,3.4c1.5-0.3,2.7-1.6,3.1-3.7l14.1-64.2C189,175.8,187.5,172,185.1,170.6z"
        />
        <path
          className="st3"
          d="M165.7,242l-81.4-47c-2.2-1.3-3.5-4.7-2.9-7.7l12.9-58.7c0.6-2.9,3-4.3,5.2-3l81.4,47
            c2.2,1.3,3.5,4.7,2.9,7.7L171,239C170.3,241.9,168,243.3,165.7,242z"
        />
        <path
          className="st5"
          d="M165.7,242l-81.4-47c-2.2-1.3-3.5-4.7-2.9-7.7l12.9-58.7c0.6-2.9,3-4.3,5.2-3l81.4,47
            c2.2,1.3,3.5,4.7,2.9,7.7L171,239C170.3,241.9,168,243.3,165.7,242z"
        />
        <path
          className="st0"
          d="M166.4,241.7l-81.4-47c-2.2-1.3-3.5-4.7-2.9-7.7L95,128.3c0.3-1.5,1.1-2.6,2.1-3.1c-1.3,0.3-2.4,1.5-2.8,3.4
            l-12.9,58.7c-0.6,2.9,0.6,6.4,2.9,7.7l81.4,47c1.1,0.6,2.2,0.6,3.2,0.1C168.1,242.3,167.3,242.2,166.4,241.7z"
        />
        <path
          className="st5"
          d="M168.8,248.9l-11.8-1.6l-76.4-44.1c-2.4-1.4-6.7-1.2-9.6,0.4l-54.1,31.2l-7.2-0.9v4.1l0,0
            c0,0.9,0.5,1.8,1.6,2.5l86.6,50c2.4,1.4,6.7,1.2,9.6-0.4l58.9-34c1.6-0.9,2.4-2.1,2.3-3.2l0,0V248.9z"
        />
        <path
          className="st1"
          d="M111.4,221.1l-5.4-3.1H46.3l-29.4,17l-7.2-0.9v4.1l0,0c0,0.9,0.5,1.8,1.6,2.5l86.6,50c1.3,0.7,3.1,1,4.9,0.9
            L111.4,221.1z"
        />
        <path
          className="st0"
          d="M12.9,234.4l-3.2-0.4v4.1l0,0c0,0.9,0.5,1.8,1.6,2.5l1.5,0.9V234.4z"
        />
        <path className="st8" d="M98.8,290.9c2.3,0.9,5.6,0.7,8.1-0.5v-9.1h-8.1V290.9z" />
        <path
          className="st2"
          d="M98,286.4l-86.6-50c-2.4-1.4-2.1-3.9,0.7-5.5l58.9-34c2.8-1.6,7.1-1.8,9.6-0.4l86.6,50
            c2.4,1.4,2.1,3.9-0.7,5.5l-58.9,34C104.7,287.7,100.4,287.9,98,286.4z"
        />
        <path
          className="st3"
          d="M166.5,250.5l-58.9,34c-2.8,1.6-7.1,1.8-9.6,0.4l-86.6-50c-0.8-0.5-1.3-1-1.5-1.7c-0.4,1.2,0.1,2.3,1.5,3.1
            l86.6,50c2.4,1.4,6.7,1.2,9.6-0.4l58.9-34c1.9-1.1,2.7-2.6,2.2-3.9C168.4,249,167.7,249.9,166.5,250.5z"
        />
        <g>
          <path
            className="st0"
            d="M48.2,219.9l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l4.6-2.7c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-4.6,2.7C49.1,220.1,48.5,220.1,48.2,219.9z"
          />
          <path
            className="st0"
            d="M54.3,223.4l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l4.6-2.7c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-4.6,2.7C55.1,223.6,54.6,223.6,54.3,223.4z"
          />
          <path
            className="st0"
            d="M60.3,226.9l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l4.6-2.7c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-4.6,2.7C61.2,227.1,60.6,227.1,60.3,226.9z"
          />
          <path
            className="st0"
            d="M68.7,224l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C69.5,224.2,69,224.2,68.7,224z"
          />
          <path
            className="st0"
            d="M74.7,227.5l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C75.6,227.7,75,227.7,74.7,227.5z"
          />
          <path
            className="st0"
            d="M80.8,231l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1L86,228
               c0.3,0.2,0.3,0.5-0.1,0.7L82,231C81.6,231.2,81.1,231.2,80.8,231z"
          />
          <path
            className="st0"
            d="M86.8,234.5l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C87.7,234.7,87.1,234.7,86.8,234.5z"
          />
          <path
            className="st0"
            d="M92.9,238l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C93.7,238.1,93.2,238.2,92.9,238z"
          />
          <path
            className="st0"
            d="M98.9,241.5l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C99.8,241.6,99.2,241.7,98.9,241.5z"
          />
          <path
            className="st0"
            d="M105,245l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C105.8,245.1,105.3,245.2,105,245z"
          />
          <path
            className="st0"
            d="M111,248.5l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C111.9,248.6,111.3,248.6,111,248.5z"
          />
          <path
            className="st0"
            d="M117.1,252l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C117.9,252.1,117.4,252.1,117.1,252z"
          />
          <path
            className="st0"
            d="M123.1,255.4l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C124,255.6,123.4,255.6,123.1,255.4z"
          />
          <path
            className="st0"
            d="M77.6,222.4l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C78.5,222.5,77.9,222.5,77.6,222.4z"
          />
          <path
            className="st0"
            d="M71.5,218.8l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C72.3,219,71.8,219,71.5,218.8z"
          />
          <path
            className="st0"
            d="M65.3,215.3l-8.4-4.9c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l8.4,4.9
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C66.2,215.4,65.6,215.5,65.3,215.3z"
          />
          <path
            className="st0"
            d="M69.6,211l-6.8-4c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l6.8,4c0.3,0.2,0.3,0.5-0.1,0.7
               l-3.9,2.2C70.5,211.1,69.9,211.1,69.6,211z"
          />
          <path
            className="st0"
            d="M83.6,225.9l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C84.5,226,84,226,83.6,225.9z"
          />
          <path
            className="st0"
            d="M89.7,229.3l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C90.6,229.5,90,229.5,89.7,229.3z"
          />
          <path
            className="st0"
            d="M95.7,232.8l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C96.6,233,96.1,233,95.7,232.8z"
          />
          <path
            className="st0"
            d="M101.8,236.3l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C102.7,236.5,102.1,236.5,101.8,236.3z"
          />
          <path
            className="st0"
            d="M107.8,239.8l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C108.7,240,108.2,240,107.8,239.8z"
          />
          <path
            className="st0"
            d="M113.9,243.3l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C114.8,243.5,114.2,243.5,113.9,243.3z"
          />
          <path
            className="st0"
            d="M119.9,246.8l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C120.8,247,120.2,247,119.9,246.8z"
          />
          <path
            className="st0"
            d="M126,250.3l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C126.8,250.5,126.3,250.5,126,250.3z"
          />
          <path
            className="st0"
            d="M132,253.8l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C132.9,254,132.3,254,132,253.8z"
          />
          <path
            className="st0"
            d="M142.8,260l-8.5-4.9c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l8.5,4.9
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C143.6,260.2,143.1,260.2,142.8,260z"
          />
          <path
            className="st0"
            d="M154.6,253.2l-7.2-4.2c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l7.2,4.2
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C155.4,253.4,154.9,253.4,154.6,253.2z"
          />
          <path
            className="st0"
            d="M94.2,225.2l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C95.1,225.3,94.6,225.4,94.2,225.2z"
          />
          <path
            className="st0"
            d="M88,221.6l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C88.9,221.8,88.4,221.8,88,221.6z"
          />
          <path
            className="st0"
            d="M81.8,218l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1L87,215
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C82.7,218.2,82.2,218.2,81.8,218z"
          />
          <path
            className="st0"
            d="M75.6,214.4l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C76.5,214.6,76,214.6,75.6,214.4z"
          />
          <path
            className="st0"
            d="M100.3,228.7l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C101.1,228.8,100.6,228.8,100.3,228.7z"
          />
          <path
            className="st0"
            d="M106.3,232.2l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C107.2,232.3,106.6,232.3,106.3,232.2z"
          />
          <path
            className="st0"
            d="M112.4,235.6l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C113.2,235.8,112.7,235.8,112.4,235.6z"
          />
          <path
            className="st0"
            d="M118.4,239.1l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C119.3,239.3,118.7,239.3,118.4,239.1z"
          />
          <path
            className="st0"
            d="M124.5,242.6l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C125.3,242.8,124.8,242.8,124.5,242.6z"
          />
          <path
            className="st0"
            d="M130.5,246.1l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C131.4,246.3,130.8,246.3,130.5,246.1z"
          />
          <path
            className="st0"
            d="M136.6,249.6l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C137.4,249.8,136.9,249.8,136.6,249.6z"
          />
          <path
            className="st0"
            d="M142.6,253.1l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C143.5,253.3,142.9,253.3,142.6,253.1z"
          />
          <path
            className="st0"
            d="M148.7,256.6l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C149.5,256.8,149,256.8,148.7,256.6z"
          />
          <path
            className="st0"
            d="M91.1,216.5l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C91.9,216.7,91.4,216.7,91.1,216.5z"
          />
          <path
            className="st0"
            d="M85,213.1l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C85.9,213.2,85.3,213.2,85,213.1z"
          />
          <path
            className="st0"
            d="M79,209.6l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C79.9,209.7,79.3,209.8,79,209.6z"
          />
          <path
            className="st0"
            d="M73,206.1l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C73.9,206.3,73.3,206.3,73,206.1z"
          />
          <path
            className="st0"
            d="M79.2,202.5l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l1.3-0.7c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-1.3,0.7C80,202.7,79.5,202.7,79.2,202.5z"
          />
          <path
            className="st0"
            d="M87,207.1l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l1.3-0.7c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-1.3,0.7C87.9,207.2,87.4,207.3,87,207.1z"
          />
          <path
            className="st0"
            d="M93,210.5l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l1.3-0.7c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-1.3,0.7C93.9,210.7,93.3,210.7,93,210.5z"
          />
          <path
            className="st0"
            d="M99,214l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l1.3-0.7c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-1.3,0.7C99.9,214.2,99.3,214.2,99,214z"
          />
          <path
            className="st0"
            d="M105,217.5l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l1.3-0.7c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-1.3,0.7C105.9,217.6,105.3,217.6,105,217.5z"
          />
          <path
            className="st0"
            d="M138.5,236.8l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l1.3-0.7c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-1.3,0.7C139.3,236.9,138.8,237,138.5,236.8z"
          />
          <path
            className="st0"
            d="M144.5,240.2l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l1.3-0.7c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-1.3,0.7C145.3,240.4,144.8,240.4,144.5,240.2z"
          />
          <path
            className="st0"
            d="M150.5,243.7l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l1.3-0.7c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-1.3,0.7C151.3,243.9,150.8,243.9,150.5,243.7z"
          />
          <path
            className="st0"
            d="M156.5,247.2l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l1.3-0.7c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-1.3,0.7C157.3,247.3,156.8,247.3,156.5,247.2z"
          />
          <path
            className="st0"
            d="M97.1,220l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C98,220.2,97.4,220.2,97.1,220z"
          />
          <path
            className="st0"
            d="M103.1,223.5l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C104,223.7,103.5,223.7,103.1,223.5z"
          />
          <path
            className="st0"
            d="M109.2,227l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C110.1,227.2,109.5,227.2,109.2,227z"
          />
          <path
            className="st0"
            d="M115.2,230.5l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C116.1,230.7,115.6,230.7,115.2,230.5z"
          />
          <path
            className="st0"
            d="M121.3,234l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C122.2,234.2,121.6,234.2,121.3,234z"
          />
          <path
            className="st0"
            d="M127.3,237.5l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C128.2,237.6,127.7,237.7,127.3,237.5z"
          />
          <path
            className="st0"
            d="M133.4,241l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C134.3,241.1,133.7,241.2,133.4,241z"
          />
          <path
            className="st0"
            d="M139.4,244.5l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C140.3,244.6,139.8,244.7,139.4,244.5z"
          />
          <path
            className="st0"
            d="M145.5,248l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C146.4,248.1,145.8,248.1,145.5,248z"
          />
          <path
            className="st0"
            d="M62.5,220.5L51,213.8c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l11.5,6.7
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C63.4,220.6,62.8,220.6,62.5,220.5z"
          />
          <path
            className="st0"
            d="M136.8,263.4l-11.5-6.7c-0.3-0.2-0.3-0.5,0.1-0.7l3.9-2.2c0.4-0.2,0.9-0.2,1.2-0.1l11.5,6.7
               c0.3,0.2,0.3,0.5-0.1,0.7l-3.9,2.2C137.7,263.5,137.1,263.5,136.8,263.4z"
          />
          <path
            className="st0"
            d="M112.6,257.1l-3.9-2.3c-0.3-0.2-0.3-0.5,0.1-0.7l4.6-2.7c0.4-0.2,0.9-0.2,1.2-0.1l3.9,2.3
               c0.3,0.2,0.3,0.5-0.1,0.7l-4.6,2.7C113.5,257.3,113,257.3,112.6,257.1z"
          />
          <path
            className="st0"
            d="M68.7,231.7l-6.3-3.6c-0.3-0.2-0.3-0.5,0.1-0.7l4.6-2.7c0.4-0.2,0.9-0.2,1.2-0.1l6.3,3.6
               c0.3,0.2,0.3,0.5-0.1,0.7l-4.6,2.7C69.6,231.9,69,231.9,68.7,231.7z"
          />
          <path
            className="st0"
            d="M106.8,253.7l-6.3-3.6c-0.3-0.2-0.3-0.5,0.1-0.7l4.6-2.7c0.4-0.2,0.9-0.2,1.2-0.1l6.3,3.6
               c0.3,0.2,0.3,0.5-0.1,0.7l-4.6,2.7C107.7,253.9,107.1,253.9,106.8,253.7z"
          />
          <path
            className="st0"
            d="M77.4,236.8l-6.3-3.6c-0.3-0.2-0.3-0.5,0.1-0.7l4.6-2.7c0.4-0.2,0.9-0.2,1.2-0.1l6.3,3.6
               c0.3,0.2,0.3,0.5-0.1,0.7l-4.6,2.7C78.3,236.9,77.8,237,77.4,236.8z"
          />
          <path
            className="st0"
            d="M77.2,229.8l27.2,15.7c0.3,0.2,0.3,0.5,0,0.7l-4.7,2.7c-0.3,0.2-0.9,0.2-1.2,0l-27.2-15.7L77.2,229.8z"
          />
          <path
            className="st0"
            d="M129.5,263.4l4.4,2.5c0.3,0.1,0.2,0.4-0.1,0.6l-1.9,1.1c-0.3,0.2-0.7,0.2-1,0l-4.4-2.5
               c-0.3-0.1-0.2-0.4,0.1-0.6l1.9-1.1C128.8,263.3,129.3,263.3,129.5,263.4z"
          />
          <path
            className="st0"
            d="M123.4,259.9l4.4,2.5c0.3,0.1,0.2,0.4-0.1,0.6l-1.9,1.1c-0.3,0.2-0.7,0.2-1,0l-4.4-2.5
               c-0.3-0.1-0.2-0.4,0.1-0.6l1.9-1.1C122.7,259.8,123.2,259.8,123.4,259.9z"
          />
          <path
            className="st0"
            d="M117.4,256.4l4.4,2.5c0.3,0.1,0.2,0.4-0.1,0.6l-1.9,1.1c-0.3,0.2-0.7,0.2-1,0l-4.4-2.5
               c-0.3-0.1-0.2-0.4,0.1-0.6l1.9-1.1C116.7,256.3,117.1,256.3,117.4,256.4z"
          />
          <path
            className="st0"
            d="M126.8,258l4.4,2.5c0.3,0.1,0.2,0.4-0.1,0.6l-1.9,1.1c-0.3,0.2-0.7,0.2-1,0l-4.4-2.5
               c-0.3-0.1-0.2-0.4,0.1-0.6l1.9-1.1C126.1,257.9,126.5,257.8,126.8,258z"
          />
        </g>
        <g>
          <polygon
            className="st3"
            points="150.7,189.8 118.1,171 118.5,168.9 151.1,187.7 			"
          />
          <polygon
            className="st3"
            points="139,193.4 116.3,180.3 116.7,178.2 139.4,191.3 			"
          />
          <polygon
            className="st9"
            points="136.9,187 117.2,175.7 117.6,173.5 137.3,184.9 			"
          />
          <polygon
            className="st2"
            points="149.8,194.5 139.9,188.8 140.3,186.7 150.2,192.4 			"
          />
        </g>
        <g>
          <path
            className="st2"
            d="M132.7,168.9c-0.3,0.7-1.1,0.9-1.8,0.5c-0.7-0.4-0.9-1.2-0.6-1.9c0.3-0.7,1.1-0.9,1.8-0.5
               C132.7,167.3,133,168.2,132.7,168.9z"
          />
          <path
            className="st3"
            d="M136.9,171.3c-0.3,0.7-1.1,0.9-1.8,0.5c-0.7-0.4-0.9-1.2-0.6-1.9c0.3-0.7,1.1-0.9,1.8-0.5
               C136.9,169.7,137.2,170.6,136.9,171.3z"
          />
          <path
            className="st2"
            d="M141.2,173.7c-0.3,0.7-1.1,0.9-1.8,0.5c-0.7-0.4-0.9-1.2-0.6-1.9c0.3-0.7,1.1-0.9,1.8-0.5
               C141.2,172.2,141.5,173.1,141.2,173.7z"
          />
          <path
            className="st9"
            d="M145.4,176.2c-0.3,0.7-1.1,0.9-1.8,0.5c-0.7-0.4-0.9-1.2-0.6-1.9c0.3-0.7,1.1-0.9,1.8-0.5
               C145.4,174.6,145.7,175.5,145.4,176.2z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="st2"
            d="M150.6,89.9l-1.7-1v-2.4c0-2.6-1.8-5.7-4.1-7l-12.7-7.3c-2.2-1.3-4.1-0.2-4.1,2.3V77l-1.7-1v-2.4
               c0-3.6,2.6-5.1,5.7-3.3l12.7,7.3c3.2,1.8,5.7,6.3,5.7,9.9V89.9z"
          />
        </g>
        <path
          className="st2"
          d="M144.9,93.2l5.7-3.3l-1.2-4l-1.6-4c0,0-2.8-3.1-3-3.1c-0.2-0.1-7.7-4.3-7.7-4.3l-6.2-4.5l-3,0.1l-5.5,3.2
            l4,1.6c0,0,2.3,1.1,2.8,1.3c0.5,0.1,2.7,2.2,2.8,2.3c0,0,2.7,1.1,2.7,1.1l0.6-0.1l4.9,3.4l1.6,0.7l0.8,1.4l1.3,3.6L144.9,93.2z"
        />
        <path
          className="st1"
          d="M128.1,77l-5.7,3.3c0,0-0.4-3.7-0.4-3.7s1.3-1.8,1.3-1.8l3.1-0.6l2.5,1.9l-0.8-0.3l-0.3,0.2l0.4,0.6V77z"
        />
        <g>
          <path
            className="st0"
            d="M144.9,93.2l-1.7-1v-2.4c0-2.6-1.8-5.7-4.1-7l-12.7-7.3c-2.2-1.3-4.1-0.2-4.1,2.3v2.4l-1.7-1v-2.4
               c0-3.6,2.6-5.1,5.7-3.3l12.7,7.3c3.2,1.8,5.7,6.3,5.7,9.9V93.2z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="st2"
            d="M208.1,89.9l-1.2-0.7c-0.5,0.4-1,0.7-1.6,1l2.8,1.6c2.2,1.3,4.1,4.4,4.1,7v2.4l1.7,1v-2.4
               C213.8,96.2,211.2,91.7,208.1,89.9z"
          />
        </g>
        <path
          className="st2"
          d="M211,94.2c0,0-2.8-3.1-3-3.1c-0.1,0-1-0.5-2.2-1.2c-1.8,1.2-3.6,2.2-5.5,3.2l2.9,2.1l1.6,0.7l0.8,1.4l1.3,3.6
            l1.1,4.7l5.7-3.3l-1.2-4L211,94.2z"
        />
        <g>
          <path
            className="st0"
            d="M202.4,95.1c2.2,1.3,4.1,4.4,4.1,7v2.4l1.7,1v-2.4c0-3.6-2.6-8.1-5.7-9.9l-1.1-0.6c-0.6,0.3-1.2,0.6-1.7,0.9
               L202.4,95.1z"
          />
        </g>
      </g>
    </g>
    <g>
      <g>
        <path
          className="st10"
          d="M311.9,49.8l-19.1-11c-0.4-0.2-0.7-0.2-1-0.1c0,0,0,0,0,0l-1.9,1.1c0,0,0.8,0.3,1.3,0.5v13.9
            c0,1.1,0.7,2.5,1.5,2.9l18.3,10.6v1.6l1.9-1.1l0,0c0.3-0.2,0.5-0.6,0.5-1.2V52.8C313.5,51.6,312.8,50.3,311.9,49.8z"
        />
        <path
          className="st9"
          d="M310,69.3l-19.1-11c-0.9-0.5-1.5-1.8-1.5-2.9V41c0-1.1,0.7-1.7,1.5-1.2l19.1,11c0.9,0.5,1.5,1.8,1.5,2.9v14.2
            C311.6,69.2,310.9,69.8,310,69.3z"
        />
        <path
          className="st3"
          d="M310.5,50.7l-19.1-11c-0.5-0.3-0.9-0.2-1.2,0c0.2,0,0.4,0,0.7,0.2l19.1,11c0.9,0.5,1.5,1.8,1.5,2.9v14.2
            c0,0.5-0.1,0.9-0.4,1.1c0.5,0,0.8-0.5,0.8-1.3V53.7C312.1,52.5,311.4,51.2,310.5,50.7z"
        />
      </g>
      <g>
        <path
          className="st3"
          d="M297.3,53.3c0,0.8-0.6,1.2-1.3,0.7c-0.7-0.4-1.3-1.4-1.3-2.2c0-0.8,0.6-1.2,1.3-0.7
            C296.8,51.5,297.3,52.5,297.3,53.3z"
        />
        <path
          className="st3"
          d="M301.3,55.6c0,0.8-0.6,1.2-1.3,0.7c-0.7-0.4-1.3-1.4-1.3-2.2c0-0.8,0.6-1.2,1.3-0.7
            C300.7,53.7,301.3,54.7,301.3,55.6z"
        />
        <path
          className="st3"
          d="M305.2,57.8c0,0.8-0.6,1.2-1.3,0.7c-0.7-0.4-1.3-1.4-1.3-2.2c0-0.8,0.6-1.2,1.3-0.7
            C304.6,56,305.2,57,305.2,57.8z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          className="st0"
          d="M301,31.9l-19.1-11c-0.4-0.2-0.7-0.2-1-0.1l0,0l-1.9,1.1c0,0,0.8,0.3,1.3,0.5v13.9c0,1.1,0.7,2.5,1.5,2.9
            l18.3,10.6v1.6l1.9-1.1l0,0c0.3-0.2,0.5-0.6,0.5-1.2V34.9C302.6,33.8,301.9,32.4,301,31.9z"
        />
        <path
          className="st2"
          d="M299.2,51.4l-19.1-11c-0.9-0.5-1.5-1.8-1.5-2.9V23.2c0-1.1,0.7-1.7,1.5-1.2l19.1,11c0.9,0.5,1.5,1.8,1.5,2.9
            v14.2C300.7,51.3,300,51.9,299.2,51.4z"
        />
        <path
          className="st3"
          d="M299.6,32.8l-19.1-11c-0.5-0.3-0.9-0.2-1.2,0c0.2,0,0.4,0,0.7,0.2l19.1,11c0.9,0.5,1.5,1.8,1.5,2.9v14.2
            c0,0.5-0.1,0.9-0.4,1.1c0.5,0,0.8-0.5,0.8-1.3V35.8C301.2,34.6,300.5,33.3,299.6,32.8z"
        />
      </g>
      <g>
        <path
          className="st3"
          d="M286.5,35.4c0,0.8-0.6,1.2-1.3,0.7c-0.7-0.4-1.3-1.4-1.3-2.2s0.6-1.2,1.3-0.7
            C285.9,33.6,286.5,34.6,286.5,35.4z"
        />
        <path
          className="st3"
          d="M290.4,37.7c0,0.8-0.6,1.2-1.3,0.7c-0.7-0.4-1.3-1.4-1.3-2.2c0-0.8,0.6-1.2,1.3-0.7
            C289.8,35.9,290.4,36.9,290.4,37.7z"
        />
        <path
          className="st3"
          d="M294.3,39.9c0,0.8-0.6,1.2-1.3,0.7s-1.3-1.4-1.3-2.2c0-0.8,0.6-1.2,1.3-0.7C293.7,38.1,294.3,39.1,294.3,39.9
            z"
        />
      </g>
    </g>
    <g>
      <polygon className="st4" points="38,296.8 28.5,291.3 38,285.9 47.4,291.3 	" />
      <polygon className="st10" points="47.4,285.2 38,290.6 38,279.7 47.4,274.3 	" />
      <polygon className="st9" points="38,279.7 28.5,274.3 38,268.8 47.4,274.3 	" />
      <polygon className="st9" points="38,290.6 28.5,285.2 28.5,274.3 38,279.7 	" />
    </g>
    <g>
      <polygon className="st4" points="38,121.3 28.5,115.9 38,110.4 47.4,115.9 	" />
      <polygon className="st5" points="47.4,109.7 38,115.2 38,104.3 47.4,98.8 	" />
      <polygon className="st2" points="38,104.3 28.5,98.8 38,93.4 47.4,98.8 	" />
      <polygon className="st0" points="38,115.2 28.5,109.7 28.5,98.8 38,104.3 	" />
    </g>
    <g>
      <polygon
        className="st4"
        points="189.6,304.6 180.2,299.1 189.6,293.7 199.1,299.1 	"
      />
      <polygon className="st5" points="199.1,293 189.6,298.4 189.6,287.5 199.1,282.1 	" />
      <polygon
        className="st2"
        points="189.6,287.5 180.2,282.1 189.6,276.6 199.1,282.1 	"
      />
      <polygon className="st0" points="189.6,298.4 180.2,293 180.2,282.1 189.6,287.5 	" />
    </g>
  </svg>
);
